import React from "react";

import Form from "./Form";
import * as style from "./style.module.scss";

interface Props {
    children: JSX.Element;
}

const ContactSection = ({ children }: Props): JSX.Element => (
    <div className={style.element}>{children}</div>
);
ContactSection.Form = Form;
export default ContactSection;
