import { Section } from "@containers";
import React from "react";

import Item from "./Item";
import * as style from "./style.module.scss";

interface Props {
    title: string;
    children: Node;
}

const Experience = ({ title, children }: Props): JSX.Element => (
    <div className={style.element}>
        <Section title={title} collapsable={true}>
            {children}
        </Section>
    </div>
);
Experience.Item = Item;
export default Experience;
