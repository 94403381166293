import { Image } from "@components";
import React from "react";

import profilePlaceholder from "../../../images/profile-placeholder.jpg";
import * as style from "./style.module.scss";

interface Props {
    image: unknown;
}

const Profile = ({ image }: Props): JSX.Element => {
    return (
        <div className={style.profileImage}>
            <Image image={image} imagePlaceholder={profilePlaceholder} />
        </div>
    );
};

export default Profile;
