import classnames from "classnames";
import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Button as _button } from "reactstrap";

import * as style from "./style.module.scss";

// Interface

interface ButtonType {
    disabled: boolean;
    children: React.ReactNode;
    type: string;
    link: string;
    label: string;
    anchor: string;
}
interface ButtonLayoutType {
    disabled: boolean;
    children: React.ReactNode;
}

/**
 * Button
 */
const ButtonLayout = ({ children, disabled }: ButtonLayoutType) => (
    <div className={classnames(style.wrapper, disabled && style.disabledBtn)}>
        {children}
    </div>
);

/**
 * Button
 */
const Button = ({
    type,
    link,
    label,
    anchor,
    disabled,
    children,
}: ButtonType): JSX.Element => {
    return (
        <ButtonLayout disabled={disabled}>
            {type === "submit" ? (
                <_button
                    className={style.button}
                    type={type}
                    disabled={disabled}
                >
                    {children}
                </_button>
            ) : anchor ? (
                <AnchorLink className={style.button} href={`#${link}`}>
                    {children || label}
                </AnchorLink>
            ) : (
                <a className={style.button} href={link}>
                    {children || label}
                </a>
            )}
        </ButtonLayout>
    );
};

export default Button;
