import { ContactSection as _ContactSection } from "@components";
import { Section } from "@containers";
import { LocaleContext } from "@context";
import { t } from "@utils/Translations";
import React, { useContext } from "react";

interface Props {
    teamProfiles: string;
    timeSlots: string;
    teamName: string;
    fill: string;
}

const ContactSection = ({
    teamProfiles,
    timeSlots,
    teamName,
    fill,
}: Props): JSX.Element => {
    const locale = useContext(LocaleContext);
    return timeSlots ? (
        <Section
            title={t("contact form title", locale)}
            subTitle={t("contact form subtitle", locale)}
            align="center"
        >
            <_ContactSection>
                <_ContactSection.Form
                    teamProfiles={teamProfiles}
                    timeSlots={timeSlots}
                    teamName={teamName}
                    fill={fill}
                />
            </_ContactSection>
        </Section>
    ) : (
        <></>
    );
};

export default ContactSection;
