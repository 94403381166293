import { Locations as _Locations, Title } from "@components";
import { LocaleContext } from "@context";
import { Location } from "@interfaces";
import { t } from "@utils/Translations";
import React, { useContext } from "react";

interface Props {
    locations: Location[];
}

const Locations = ({ locations }: Props): JSX.Element => {
    const locale = useContext(LocaleContext);
    return (
        <>
            <div className="container-lg no-gutters-mobile">
                <Title tag="h2">{t("locations title", locale)}</Title>
            </div>
            <_Locations>
                {locations && (
                    <>
                        {locations.map(location => {
                            return (
                                <_Locations.Card
                                    key={location.id}
                                    location={location}
                                />
                            );
                        })}
                    </>
                )}
            </_Locations>
        </>
    );
};

export default Locations;
