import { Socials as _Socials } from "@components";
import React from "react";

interface Props {
    socials: string;
}

const socialsHasLink = socials => {
    for (const social in socials) {
        if (socials[social]) {
            return true;
        }
    }
    return false;
};

const Socials = ({ socials }: Props): JSX.Element => {
    return socialsHasLink(socials) ? <_Socials socials={socials} /> : <></>;
};

export default Socials;
