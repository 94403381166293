import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    ExcelIcon,
    ImageIcon,
    PdfIcon,
    PowerpointIcon,
    WordIcon,
} from "@icons";
import { Document as DocumentType } from "@interfaces";
import classNames from "classnames";
import React from "react";
import SVG from "react-inlinesvg";

import * as style from "./style.module.scss";

interface Props {
    documentItem: DocumentType;
}

const DocumentListItem = ({ documentItem: { file } }: Props): JSX.Element => {
    const getIcon = fileType => {
        if (fileType.indexOf("spreadsheet") > 0) {
            return ExcelIcon;
        }
        if (fileType.indexOf("image") > 0) {
            return ImageIcon;
        }
        if (fileType.indexOf("pdf") > 0) {
            return PdfIcon;
        }
        if (fileType.indexOf("presentation") > 0) {
            return PowerpointIcon;
        }
        if (fileType.indexOf("word") > 0) {
            return WordIcon;
        }
        return ImageIcon;
    };
    return (
        <li className="col-md-6 col-lg-3 mb-1 mb-md-2 px-1">
            <div className={style.fileListItem}>
                <div className="d-flex flex-md-column justify-content-md-center align-items-center flex-grow-1">
                    <div>
                        <SVG
                            className={style.fileIcon}
                            src={getIcon(file.contentType)}
                            preProcessor={code =>
                                code.replace(/fill=".*?"/g, `fill=red`)
                            }
                        />
                    </div>
                    <a href={file.url} download className={style.fileLink}>
                        {file.fileName}
                    </a>
                </div>
                <div
                    className={classNames(
                        style.fileDownloadOverlay,
                        "flex-grow-0",
                    )}
                >
                    <div className={style.icon}>
                        <FontAwesomeIcon
                            icon={faArrowDown}
                            aria-hidden="true"
                        />
                    </div>
                    <span className={style.text}>Download</span>
                </div>
            </div>
        </li>
    );
};

export default DocumentListItem;
