import { renderRichText } from "gatsby-source-contentful/rich-text";
import React from "react";

import * as style from "./style.module.scss";

interface Props {
    rawText: string;
}

const RichText = ({ rawText }: Props): JSX.Element => (
    <div className={style.element}>
        {rawText ? renderRichText(rawText) : ""}
    </div>
);

export default RichText;
