import classNames from "classnames";
import React from "react";

import * as style from "./style.module.scss";

interface Props {
    value: number;
    maxValue?: number;
    color?: string;
}

const Rating = ({ value, maxValue, color }: Props): JSX.Element => {
    value = value || 0;
    maxValue = maxValue || 5;
    const dots = [];

    for (let i = 1; i <= maxValue; i++) {
        dots.push(
            <svg
                key={i}
                height="16"
                width="16"
                className={classNames(style.dots, {
                    [style.dotsActive]: i <= value,
                })}
                {...(i <= value ? { style: { fill: color } } : {})}
            >
                <circle cx="8" cy="8" r="8" />
                Sorry, your browser does not support inline SVG.
            </svg>,
        );
    }

    return <div className={style.element}>{dots}</div>;
};

export default Rating;
