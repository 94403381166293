import { Section } from "@containers";
import { LocaleContext } from "@context";
import { t } from "@utils/Translations";
import classNames from "classnames";
import React, { useContext } from "react";

import LanguageListItem from "../LanguageListItem";
import * as style from "./style.module.scss";

interface Language {
    id: string;
    language: string;
    level: string;
    stars: number;
}
interface Props {
    languages: Language[];
}

const LanguagesList = ({ languages }: Props): JSX.Element => {
    const locale = useContext(LocaleContext);
    return (
        <div className={classNames("col-md-6", style.element)}>
            <Section title={t("languages title", locale)}>
                {languages &&
                    languages.map(language => (
                        <LanguageListItem
                            key={language.id}
                            language={language.language}
                            level={language.level}
                            stars={language.stars}
                        />
                    ))}
            </Section>
        </div>
    );
};
export default LanguagesList;
