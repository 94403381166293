import classnames from "classnames";
import React from "react";

type Props = {
    tag: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    className?: string;
    children?: React.ReactNode;
};

const Title = ({ tag: Component, className, children }: Props): JSX.Element => (
    <Component className={classnames(className)}>{children}</Component>
);

export default Title;
