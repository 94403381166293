import { LanguagesList as _LanguagesList } from "@components";
import type { LanguageLevel } from "@enums/LanguageLevel.enums";
import React from "react";

interface Language {
    id: string;
    language: string;
    level: string;
    stars: number;
    reading: LanguageLevel;
    speaking: LanguageLevel;
    writing: LanguageLevel;
}

interface Props {
    languages: Language[];
}

const LanguagesList = ({ languages }: Props): Node => {
    //Sort languages by stars descending
    languages.sort((a, b) => (a.stars < b.stars ? 1 : -1));
    return <_LanguagesList languages={languages} />;
};
export default LanguagesList;
