import { ContactPerson as _ContactPerson } from "@components";
import { Section } from "@containers";
import React from "react";

interface Props {
    person: Person;
}
interface Person {
    id: string;
    firstName: string;
    name: string;
    jobTitle: string;
    email: string;
    phoneNumber: string;
    portrait: string;
}

const ContactPerson = ({ person }: Props): JSX.Element => (
    <Section>
        <_ContactPerson
            key={person.id}
            fullName={`${person.firstName} ${person.name}`}
            jobTitle={person.jobTitle}
            email={person.email}
            phoneNumber={person.phoneNumber}
            portrait={person.portrait}
        />
    </Section>
);

export default ContactPerson;
