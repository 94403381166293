import { LocaleContext } from "@context";
import { t } from "@utils/Translations";
import classNames from "classnames";
import React, { useContext, useState } from "react";
import slugify from "react-slugify";
import { Col, Container, Row } from "reactstrap";

import Button from "../../Button/";
import * as style from "./style.module.scss";

interface Props {
    teamProfiles: unknown;
    timeSlots: unknown;
    teamName: string;
}

const Form = ({ teamProfiles, timeSlots, teamName }: Props): JSX.Element => {
    const locale = useContext(LocaleContext);

    const initialData = {
        name: "",
        email: "",
    };
    const [inputs, setInputs] = useState(initialData);
    const [errors, setErrors] = useState(initialData);

    // Validation
    const validate = inputs => {
        //Email errors
        const errors = { email: "", name: "null" };
        if (!inputs.email) {
            errors.email = t("required field", locale);
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputs.email)
        ) {
            errors.email = t("invalid email", locale);
        }
        //Name Errors
        if (!inputs.name) {
            errors.name = t("required field", locale);
        }
        setErrors(errors);
        return errors;
    };

    // handleSubmit
    const handleSubmit = event => {
        const allErrors = validate(inputs);
        const hasErrors = Object.keys(allErrors).length !== 0;
        if (hasErrors) {
            event.preventDefault();
        }
    };

    //handleInputChange
    const handleInputChange = event => {
        event.persist();
        const newInputValues = {
            ...inputs,
            [event.target.name]: event.target.value,
        };
        setInputs(newInputValues);
        validate(newInputValues);
    };

    //return
    return (
        <Container fluid="true" className={style.element}>
            <form
                name="Contact Form"
                method="POST"
                data-netlify="true"
                noValidate
                onSubmit={handleSubmit}
            >
                <input type="hidden" name="form-name" value="Contact Form" />
                <input
                    type="hidden"
                    id="subject"
                    name="subject"
                    value={`Contactrequest from ${teamName}`}
                />
                <Row>
                    <Col sm="6">
                        <fieldset>
                            <legend>
                                {t("contact form people title", locale)}
                            </legend>
                            {teamProfiles &&
                                teamProfiles.map(teamProfile => (
                                    <div
                                        className={style.formCheck}
                                        key={teamProfile.id}
                                    >
                                        <input
                                            id={`${slugify(
                                                teamProfile.person.name +
                                                    teamProfile.person
                                                        .firstName,
                                            )}`}
                                            type="checkbox"
                                            name="TalkTo[]"
                                            value={`${teamProfile.person.name} ${teamProfile.person.firstName}`}
                                        />
                                        <label
                                            htmlFor={`${slugify(
                                                teamProfile.person.name +
                                                    teamProfile.person
                                                        .firstName,
                                            )}`}
                                        >
                                            <span>
                                                {`${teamProfile.person.name} ${teamProfile.person.firstName}`}
                                            </span>
                                            <span
                                                className={style.checkmark}
                                            ></span>
                                        </label>
                                    </div>
                                ))}
                        </fieldset>
                    </Col>
                    <Col sm="6">
                        <fieldset>
                            <legend>
                                {t("contact form timeslot title", locale)}
                            </legend>
                            {timeSlots &&
                                timeSlots.map(timeSlot => (
                                    <div
                                        className={style.formCheck}
                                        key={timeSlot.id}
                                    >
                                        <input
                                            id={slugify(
                                                `${timeSlot.date}, ${timeSlot.startTime} - ${timeSlot.endTime}`,
                                            )}
                                            type="checkbox"
                                            name="TimeSlots[]"
                                            value={`${timeSlot.date}, ${timeSlot.startTime} - ${timeSlot.endTime}`}
                                        />
                                        <label
                                            htmlFor={slugify(
                                                `${timeSlot.date}, ${timeSlot.startTime} - ${timeSlot.endTime}`,
                                            )}
                                        >
                                            <span>
                                                {`${timeSlot.date}, ${timeSlot.startTime} - ${timeSlot.endTime}`}
                                            </span>
                                            <span
                                                className={style.checkmark}
                                            ></span>
                                        </label>
                                    </div>
                                ))}
                        </fieldset>
                    </Col>
                </Row>
                <Row style={{ marginTop: "2em" }}>
                    <Col sm="6">
                        <div className={style.formGroup}>
                            <div className="d-flex">
                                <label htmlFor="name">
                                    <span className={style.labelWrap}>
                                        <strong>
                                            {t("form label name", locale)} *
                                        </strong>
                                    </span>
                                </label>
                                {errors.name && (
                                    <span
                                        className={classNames(
                                            style.errorMsg,
                                            "flex-shrink-0",
                                        )}
                                    >
                                        {errors.name}
                                    </span>
                                )}
                            </div>

                            <input
                                type="text"
                                placeholder={t("form label name", locale)}
                                name="name"
                                id="name"
                                className={classNames({
                                    [style.error]: errors.name,
                                })}
                                onChange={handleInputChange}
                                value={inputs.name}
                            />
                        </div>
                    </Col>
                    <Col sm="6">
                        <div className={style.formGroup}>
                            <div className="d-flex">
                                <label>
                                    <span className={style.labelWrap}>
                                        <strong>
                                            {t("form label email", locale)} *
                                        </strong>
                                    </span>
                                </label>
                                {errors.email && (
                                    <span
                                        className={classNames(
                                            style.errorMsg,
                                            "flex-shrink-0",
                                        )}
                                    >
                                        {errors.email}
                                    </span>
                                )}
                            </div>
                            <input
                                type="email"
                                placeholder={t("form label email", locale)}
                                name="email"
                                id="email"
                                className={classNames({
                                    [style.error]: errors.email,
                                })}
                                onChange={handleInputChange}
                                value={inputs.email}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={style.formGroup}>
                            <label htmlFor="remarks">
                                <strong>
                                    {t("form label remarks", locale)}
                                </strong>
                            </label>
                            <textarea
                                id="remarks"
                                name="Remarks"
                                rows="4"
                                cols="50"
                            />
                        </div>
                    </Col>
                </Row>
                <div className="text-sm-center">
                    <Button type="submit">
                        {t("contact form submit", locale)}
                    </Button>
                </div>
            </form>
        </Container>
    );
};
export default Form;
