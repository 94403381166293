import { Education as EducationType } from "@interfaces";
import React from "react";

import * as style from "./style.module.scss";

const EducationItem = ({
    startYear,
    title,
    institute,
}: EducationType): JSX.Element => (
    <div className={style.element}>
        <div className={style.endYear}>{startYear}</div>
        <div>
            <div className={style.title}>{title}</div>
            <div className={style.institute}>{institute}</div>
        </div>
    </div>
);

export default EducationItem;
