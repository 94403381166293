import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

import noImage from "../../images/image-placeholder.jpg";

interface Props {
    image: unknown;
    imageClass?: string;
    imagePlaceholder?: string;
}

const Image = ({ image, imageClass, imagePlaceholder }: Props): JSX.Element => {
    if (image) {
        if (getImage(image)) {
            return (
                <GatsbyImage
                    className={imageClass}
                    image={getImage(image)}
                    alt="Portrait"
                />
            );
        }
        if (image.file && image.file.contentType !== "image/svg+xml") {
            return (
                <img
                    className={imageClass}
                    src={image.file.url}
                    alt="Portrait"
                />
            );
        }
    }
    return (
        <img
            className={imageClass}
            src={imagePlaceholder ?? noImage}
            alt="Portrait"
        />
    );
};
export default Image;
