import { ProfileImage, Title } from "@components";
import { LocaleContext } from "@context";
import { Person } from "@interfaces";
import { t } from "@utils/Translations";
import classNames from "classnames";
import React, { useContext } from "react";

import RichText from "../RichText";
import * as style from "./style.module.scss";

const PersonCard = ({
    fullName,
    jobTitle,
    region,
    about,
    driversLicense,
    portrait,
    mainOffice,
}: Person): JSX.Element => {
    const locale = useContext(LocaleContext);
    return (
        <div className={classNames(style.element, "mb-4")}>
            <div className="row">
                <div className="col-sm-4 col-md-3">
                    <ProfileImage image={portrait} />
                </div>
                <div className="col-sm-8 col-md-9">
                    <Title tag="h1" className="mb-0">
                        {fullName}
                    </Title>
                    <Title tag="h2" className="h3">
                        {jobTitle}
                    </Title>
                    <div className="mb-2">
                        {region && <div className="h4">{region}</div>}
                        {mainOffice && (
                            <div className="h4">
                                <b>{t("main office of work", locale)}:</b>{" "}
                                {mainOffice.name}
                            </div>
                        )}
                        {driversLicense && (
                            <div className="h4">
                                <b>{t("drivers license", locale)}:</b>{" "}
                                {driversLicense}
                            </div>
                        )}
                    </div>

                    {about && <RichText rawText={about} />}
                </div>
            </div>
        </div>
    );
};
export default PersonCard;
