import { ContactPerson, Locations, MapboxMap, Socials } from "@containers";
import { Theme } from "@interfaces";
import classNames from "classnames";
import React from "react";

import * as style from "./style.module.scss";

interface Props {
    theme: Theme;
    contactPerson: ContactPerson;
}

const Footer = ({ theme, contactPerson }: Props): JSX.Element => {
    const { locations, facebook, twitter, instagram, linkedIn } = theme;
    return (
        <>
            <div className="container-fluid">
                {contactPerson && <ContactPerson person={contactPerson} />}
                <div className="row">
                    <div className={classNames(style.mapCol, "col-md-6 mb-2")}>
                        {locations && <MapboxMap locations={locations} />}
                    </div>
                    <div className="col-md mb-2">
                        {locations && <Locations locations={locations} />}
                    </div>
                </div>
            </div>
            <div className="container-lg">
                <Socials
                    socials={{
                        facebook: facebook,
                        twitter: twitter,
                        instagram: instagram,
                        linkedIn: linkedIn,
                    }}
                />
            </div>
        </>
    );
};

export default Footer;
