import React from "react";

import * as style from "./style.module.scss";

interface Props {
    label: string;
}

const Tag = ({ label }: Props): JSX.Element => {
    return <li className={style.element}>{label}</li>;
};

export default Tag;
