import { Document as DocumentType } from "@interfaces";
import React from "react";

import Item from "./Item";

interface Props {
    documents: DocumentType[];
}

const DocumentList = ({ documents }: Props): JSX.Element => {
    return (
        <ul className="list-unstyled row mx-n1">
            {documents.map(documentItem => (
                <Item key={documentItem.id} documentItem={documentItem} />
            ))}
        </ul>
    );
};

export default DocumentList;
