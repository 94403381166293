import classNames from "classnames";
import React from "react";

import Card from "./Card";

interface Props {
    children: Node;
}

const Locations = ({ children }: Props): JSX.Element => (
    <div className="container-lg no-gutters-mobile">
        <div>
            <div className={classNames("row pt-1")}>{children}</div>
        </div>
    </div>
);
Locations.Card = Card;
export default Locations;
