import { Section } from "@containers";
import React from "react";

import Item from "./Item";
import List from "./List";
import * as style from "./style.module.scss";

interface Props {
    title: string;
    children: React.ReactNode;
}

const Skills = ({ title, children }: Props): JSX.Element => {
    return (
        <div>
            <Section title={title} collapsable={true}>
                <div className={style.skillsWrap}>{children}</div>
            </Section>
        </div>
    );
};
Skills.List = List;
Skills.Item = Item;
export default Skills;
