import React from "react";

import AdditionalSectionItem from "./Item";

interface AdditionalSection {
    id: string;
    title: string;
    textBlock?: {
        raw: string;
    };
}
interface Props {
    additionalSections: AdditionalSection[];
}

const AdditionalSectionList = ({ additionalSections }: Props): JSX.Element => (
    <>
        {additionalSections &&
            additionalSections.map(additionalSection => (
                <AdditionalSectionItem
                    key={additionalSection.id}
                    additionalSection={additionalSection}
                />
            ))}
    </>
);

export default AdditionalSectionList;
