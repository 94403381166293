import { ProfileImage } from "@components";
import { LocaleContext } from "@context";
import { Person } from "@interfaces";
import { t } from "@utils/Translations";
import React, { useContext } from "react";

import * as style from "./style.module.scss";

const ContactPerson = ({
    fullName,
    jobTitle,
    email,
    phoneNumber,
    portrait,
}: Person): JSX.Element => {
    const locale = useContext(LocaleContext);
    return (
        <div className={style.element}>
            <h2>{t("contact person title", locale)}</h2>
            <div className={style.personCardImage}>
                <ProfileImage image={portrait} />
            </div>
            <h3>{fullName}</h3>
            <h4 className={style.jobTitle}>{jobTitle}</h4>
            {phoneNumber && (
                <a
                    href={`tel:${phoneNumber.replace(/\s/g, "")}`}
                    className={style.phoneNumber}
                >
                    {phoneNumber}
                </a>
            )}

            <a href={`mailto:${email}?subject=Let's connect...`}>{email}</a>
        </div>
    );
};

export default ContactPerson;
