import { RichText } from "@components";
import { Section } from "@containers";
import React from "react";

interface AdditionalSection {
    id: string;
    title: string;
    textBlock?: {
        raw: string;
    };
}

interface Props {
    additionalSection: AdditionalSection;
}

const AdditionalSectionItem = ({
    additionalSection: { title, textBlock },
}: Props): JSX.Element => {
    return (
        <Section title={title}>
            <RichText rawText={textBlock} />
        </Section>
    );
};

export default AdditionalSectionItem;
