import { LocaleContext } from "@context";
import { t } from "@utils/Translations";
import { Chart as ChartJS, registerables } from "chart.js";
import React, { useContext } from "react";
import { Doughnut } from "react-chartjs-2";

import { Skills as _Skills } from "../../components";
import * as style from "./style.module.scss";

interface Skill {
    category: string;
    id: string;
    skillLevel: number;
    title: string;
}
interface Props {
    data: [Skill];
}

const Skills = ({ data }: Props): JSX.Element => {
    const locale = useContext(LocaleContext);
    ChartJS.register(...registerables);
    const skillsByCategory = data
        .filter(skill => skill.skillLevel > 1)
        .sort((a, b) => {
            if (a.skillLevel !== b.skillLevel) {
                return b.skillLevel - a.skillLevel;
            }

            if (a.title < b.title) {
                return -1;
            }
            if (a.title > b.title) {
                return 1;
            }
            if (a.title === b.title) {
                return 0;
            }
        })
        .reduce(
            (groups, item) => ({
                ...groups,
                [item.category]: [...(groups[item.category] || []), item],
            }),
            {},
        );

    const skillNumbers = Object.keys(skillsByCategory).map(category => {
        return skillsByCategory[category]
            .map(skillItem => {
                return skillItem.skillLevel;
            })
            .reduce((a, b) => a + b);
    });

    const onlySkills = Object.keys(skillsByCategory).map(item =>
        t(item, locale),
    );

    const backgroundColors = [
        "#e74c3c",
        "#f39c12",
        "#fdcb6e",
        "#2ecc71",
        "#48dbfb",
        "#3742fa",
        "#5f27cd",
    ];

    return (
        <_Skills title={t("skills title", locale)}>
            <div className="w-100">
                <div className="row">
                    <div className="col-md-6 col-lg-4 d-none d-md-block">
                        <div className={style.chartContainer}>
                            <Doughnut
                                data={{
                                    labels: onlySkills,
                                    datasets: [
                                        {
                                            label: "Label 1",
                                            data: skillNumbers,
                                            backgroundColor: backgroundColors,
                                        },
                                    ],
                                }}
                                options={{
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            labels: {
                                                boxWidth: 12,
                                                usePointStyle: true,
                                                padding: 20,
                                            },
                                            position: "bottom",
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-8 d-lg-flex flex-wrap">
                        {skillsByCategory &&
                            Object.keys(skillsByCategory).map((key, index) => (
                                <_Skills.List key={key} title={key}>
                                    {skillsByCategory[key].map(skill => (
                                        <_Skills.Item
                                            key={skill.id}
                                            title={skill.title}
                                            skillLevel={skill.skillLevel}
                                            color={backgroundColors[index]}
                                        />
                                    ))}
                                </_Skills.List>
                            ))}
                    </div>
                </div>
            </div>
        </_Skills>
    );
};

export default Skills;
