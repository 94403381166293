import "mapbox-gl/dist/mapbox-gl.css";

import mbxGeocoding from "@mapbox/mapbox-sdk/services/geocoding";
import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef } from "react";

import * as style from "./style.module.scss";

interface Props {
    locations: string[];
}

const MapboxMap = ({ locations }: Props): JSX.Element => {
    const getGeocodingClient = () => {
        mapboxgl.accessToken = process.env.GATSBY_MAPBOX_API_KEY;
        if (mapboxgl.accessToken) {
            return mbxGeocoding({
                accessToken: mapboxgl.accessToken,
            });
        }
        return null;
    };

    const geocodingClient = getGeocodingClient();

    const locationsJson = JSON.parse(locations);

    const mapContainer = useRef(null);
    const map = useRef(null);

    const initPopup = location => {
        return new mapboxgl.Popup({ offset: 25 }).setHTML(
            `<h3>${location.name}</h3>
            <div>
                ${location.addressLine1 ? location.addressLine1 + "<br>" : ""}
                ${location.addressLine2 ? location.addressLine2 + "<br>" : ""}
                ${location.postalCode} ${location.city}<br>
                ${location.province ? location.province + "<br>" : ""}
                ${location.country ?? ""}
            </div>
            ` +
                (location.directionsUrl
                    ? `<a 
            href="${location.directionsUrl}" 
            target="_blank" 
            rel="noopener noreferrer"
            class="d-inline-block mt-1"
        >Get directions</a>`
                    : ""),
        );
    };

    useEffect(() => {
        if (map.current || !geocodingClient) return; // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/mapbox/light-v10",
            zoom: 3,
        });

        map.current.on("load", () => {
            map.current.resize();
        });

        locationsJson.forEach((location, key) => {
            geocodingClient
                .forwardGeocode({
                    query: location.addressString,
                    autocomplete: false,
                    limit: 1,
                })
                .send()
                .then(response => {
                    if (
                        !response ||
                        !response.body ||
                        !response.body.features ||
                        !response.body.features.length
                    ) {
                        console.error("Invalid response:");
                        console.error(response);
                        return;
                    }
                    const feature = response.body.features[0];
                    if (key === 0) {
                        map.current.setCenter(feature.center);
                    }

                    // create the popup
                    const popup = initPopup(location);

                    // Create a marker and add it to the map.
                    new mapboxgl.Marker()
                        .setLngLat(feature.center)
                        .setPopup(popup) // sets a popup on this marker
                        .addTo(map.current);
                });
        });
    });
    return (
        <div className={style.element}>
            <div ref={mapContainer} className="map-container" />
        </div>
    );
};

export default MapboxMap;
