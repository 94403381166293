import { Title } from "@components";
import { LocaleContext } from "@context";
import {
    faFacebookF,
    faInstagram,
    faLinkedinIn,
    faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "@utils/Translations";
import classNames from "classnames";
import React, { useContext } from "react";

import * as style from "./style.module.scss";

const SOCIALSMAP = {
    facebook: faFacebookF,
    linkedIn: faLinkedinIn,
    instagram: faInstagram,
    twitter: faTwitter,
};

interface Props {
    socials: {
        facebook?: string;
        linkedIn?: string;
        instagram?: string;
        twitter?: string;
    };
}

const Socials = ({ socials }: Props): JSX.Element => {
    const locale = useContext(LocaleContext);
    return (
        <>
            {socials && (
                <div className="d-flex flex-wrap justify-content-center align-items-center mb-2">
                    <Title tag="h3" className="h4 mr-1 mb-0">
                        {t("follow us", locale)}
                    </Title>
                    <ul
                        className={classNames(
                            style.socials,
                            "list-unstyled mb-0",
                        )}
                    >
                        {Object.keys(socials).map(social => {
                            return (
                                socials[social] && (
                                    <li key={social}>
                                        <a
                                            href={socials[social]}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            title={`Visit our ${social} page`}
                                        >
                                            <FontAwesomeIcon
                                                icon={SOCIALSMAP[social]}
                                                className={style.icon}
                                                aria-hidden="true"
                                            />
                                        </a>
                                    </li>
                                )
                            );
                        })}
                    </ul>
                </div>
            )}
        </>
    );
};

export default Socials;
