import { EducationList as _EducationList } from "@components";
import { Education as EducationType } from "@interfaces";
import React from "react";

interface Props {
    educations: EducationType[];
}

const EducationList = ({ educations }: Props): JSX.Element => {
    const sortedEducation = educations.sort((a, b) => {
        return (
            new Date(`${b.startMonth} ${b.startYear}`) -
            new Date(`${a.startMonth} ${a.startYear}`)
        );
    });
    return <_EducationList educations={sortedEducation} />;
};

export default EducationList;
