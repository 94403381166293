export const t = (
    value: string,
    locale: string,
    uppercase?: boolean,
    uppercaseFirstLetter?: boolean,
): string => {
    let translation = "";
    if (translations[value?.toLowerCase()]) {
        translation = translations[value?.toLowerCase()][locale.split("-")[0]];
    } else {
        translation = value;
    }

    if (!translation) {
        translation = translations[value?.toLowerCase()]["en"];
    }

    return uppercase
        ? translation.toUpperCase()
        : uppercaseFirstLetter
        ? upperCaseFirstLetter(translation)
        : translation;
};

export const availableLanguages = { nl: "Nederlands", en: "English" };

const upperCaseFirstLetter = (value: string) => {
    return `${value.substring(0, 1).toUpperCase()}${value.substring(
        1,
        value.length,
    )}`;
};

const translations = {
    "our team": { en: "Our Team", nl: "Ons Team" },
    "documents title": { en: "Documents", nl: "Documenten" },
    "view cv": { en: "View CV", nl: "Bekijk CV" },
    region: { en: "Region", nl: "Regio" },
    "main office of work": { en: "Main Work Office", nl: "Hoofdkantoor werk" },
    "contact form title": {
        en: "Would you like to have a personal chat?",
        nl: "Zin in een babbel?",
    },
    "contact form subtitle": {
        en: "Let us know which profiles you're interested in.",
        nl: "Laat ons weten welke profielen jou interesseren",
    },
    "contact form people title": {
        en: "Who would you like to talk to?",
        nl: "Met wie wil je graag samenzitten?",
    },
    "contact form timeslot title": {
        en: "When are you available to talk?",
        nl: "Wanneer ben je beschikbar",
    },
    "form label name": { en: "Name", nl: "Naam" },
    "form label email": { en: "Email", nl: "Email" },
    "form label remarks": {
        en: "Remarks & Questions?",
        nl: "Opmerkingen of vragen?",
    },
    "contact form submit": { en: "Let's Talk", nl: "Let's Talk" },
    "invalid email": { en: "Invalid email", nl: "Ongeldig emailadres" },
    "required field": {
        en: "This field is required",
        nl: "Dit veld is verplicht",
    },
    "contact person title": {
        en: "Don't Be Shy. Let's Connect!",
        nl: "Don't Be Shy. Let's Connect!",
    },
    "locations title": {
        en: "You can find us here",
        nl: "Vind ons hier",
    },
    "follow us": {
        en: "Follow us",
        nl: "Volg ons",
    },
    "back to team": {
        en: "Back to team",
        nl: "Terug naar het team",
    },
    "drivers license": {
        en: "Driver's license",
        nl: "Rijbewijs",
    },
    "languages title": {
        en: "Languages",
        nl: "Talen",
    },
    "education title": {
        en: "Education",
        nl: "Opleidingen",
    },
    "skills title": {
        en: "Skills",
        nl: "Skills",
    },
    "experience title": {
        en: "Experience",
        nl: "Werkervaring",
    },
    native: {
        en: "Native",
        nl: "Moedertaal",
    },
    "business fluent": {
        en: "Business Fluent",
        nl: "Vloeiend",
    },
    good: {
        en: "Good",
        nl: "Goed",
    },
    decent: {
        en: "Decent",
        nl: "Degelijk",
    },
    notions: {
        en: "Notions",
        nl: "Noties",
    },
    technical: {
        en: "Technical",
        nl: "Technisch",
    },
    "ux/design": {
        en: "UX/Design",
        nl: "UX/Design",
    },
    analysis: {
        en: "Analysis",
        nl: "Analyse",
    },
    marketing: {
        en: "Marketing",
        nl: "Marketing",
    },
    other: {
        en: "Other",
        nl: "Andere",
    },
};
