import { PersonCard as _PersonCard } from "@components";
import { Location } from "@interfaces";
import React from "react";

interface Person {
    name: string;
    firstName: string;
    jobTitle: string;
    region: string;
    about: string;
    driversLicense: string;
    dateOfBirth: Date;
    portrait?: string;
    mainOffice?: Location;
}
interface Props {
    person: Person;
}

const PersonCard = ({ person }: Props): JSX.Element => (
    <_PersonCard
        fullName={`${person.firstName} ${person.name}`}
        jobTitle={person.jobTitle}
        region={person.region}
        about={person.about}
        driversLicense={person.driversLicense}
        dateOfBirth={person.dateOfBirth}
        portrait={person.portrait}
        mainOffice={person.mainOffice}
    />
);

export default PersonCard;
