import React from "react";

import Rating from "../../../components/Rating";
import * as style from "./style.module.scss";

interface Props {
    title: string;
    skillLevel: number;
    color: string;
}

const Item = ({ title, skillLevel, color }: Props): JSX.Element => {
    return (
        <div className={style.element}>
            <div>{title}</div>
            <Rating value={skillLevel} color={color} />
        </div>
    );
};

export default Item;
