import { MapboxMap as _MapboxMap } from "@components";
import React from "react";

interface Location {
    id: string;
    name: string;
    addressLine1: string;
    addressLine2?: string;
    city: string;
    postalCode: string;
    province: string;
    country: string;
    directionsUrl: string;
}

interface Props {
    locations: Location[];
}

const MapboxMap = ({ locations }: Props): JSX.Element => {
    const locationWithQueries = locations.map(
        ({ addressLine1, addressLine2, city, postalCode }, key) => {
            return {
                ...locations[key],
                ...{
                    addressString: `${addressLine1} ${addressLine2 ?? ""} ${
                        postalCode ?? ""
                    } ${city ?? ""}`,
                },
            };
        },
    );
    return <_MapboxMap locations={JSON.stringify(locationWithQueries)} />;
};

export default MapboxMap;
