import { LocaleContext } from "@context";
import { t } from "@utils/Translations";
import React, { useContext } from "react";

import Rating from "../Rating";
import * as style from "./style.module.scss";

interface Props {
    language: string;
    level: string;
    stars: number;
}

const LanguageListItem = ({ language, level, stars }: Props): JSX.Element => {
    language = language || "";
    level = level || "";
    stars = stars || 0;

    const locale = useContext(LocaleContext);

    return (
        <div className={style.element}>
            <h3>{language}</h3>
            <Rating value={stars} maxValue="5" />
            <h4>{t(level, locale)}</h4>
        </div>
    );
};

export default LanguageListItem;
