import { Section } from "@containers";
import { LocaleContext } from "@context";
import { Education as EducationType } from "@interfaces";
import { t } from "@utils/Translations";
import classNames from "classnames";
import React, { useContext } from "react";

import EducationItem from "../EducationItem";
import * as style from "./style.module.scss";

interface Props {
    educations: EducationType[];
}

const EducationList = ({ educations }: Props): JSX.Element => {
    const locale = useContext(LocaleContext);
    return (
        <div className={classNames(style.element, "col-md-6")}>
            <Section title={t("education title", locale)}>
                {educations &&
                    educations.map(education => (
                        <EducationItem
                            key={education.id}
                            startYear={education.startYear}
                            title={education.title}
                            institute={education.institute}
                        />
                    ))}
            </Section>
        </div>
    );
};

export default EducationList;
