import { Image } from "@components";
import { LocaleProvider } from "@context";
import { RouteLocation, Theme } from "@interfaces";
import { availableLanguages } from "@utils/Translations";
import classNames from "classnames";
import { graphql, Link, useStaticQuery } from "gatsby";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import * as style from "./style.module.scss";

interface Props {
    theme?: Theme;
    lang: string;
    title: string;
    children: React.ReactNode;
    location?: RouteLocation;
    teamLanguage?: string;
}

const Layout = ({
    theme,
    lang,
    title,
    location,
    children,
    teamLanguage,
}: Props): JSX.Element => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `,
    );

    const defaultTitle = site.siteMetadata?.title;

    const isHome =
        location.pathname.substring(location.pathname.indexOf("/", 1) + 1) ===
        `/${lang}`;

    useEffect(() => {
        if ((typeof window !== "undefined" || window.document) && theme) {
            setCssVariables(theme);
        }
    });

    return (
        <LocaleProvider value={lang}>
            <Helmet
                htmlAttributes={{
                    lang,
                }}
                title={title}
                titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
                bodyAttributes={{
                    class: theme?.internalLabel,
                }}
            >
                <meta name="robots" content="noindex" />
            </Helmet>
            <header className={style.header}>
                <div className="container-lg">
                    <div className="d-flex justify-content-between">
                        <div>
                            {theme && (
                                <>
                                    {theme.background && (
                                        <Image
                                            image={theme.background}
                                            imageClass={style.bgImage}
                                        />
                                    )}

                                    <Image
                                        image={theme.logo}
                                        imageClass={style.logo}
                                    />
                                </>
                            )}
                        </div>
                        {teamLanguage === "all" && (
                            <div className={style.langswitcher}>
                                <ul className={classNames("list-unstyled")}>
                                    {Object.keys(availableLanguages).map(
                                        (langCode, key) => {
                                            return (
                                                <li
                                                    className={classNames({
                                                        active:
                                                            lang.split(
                                                                "-",
                                                            )[0] ===
                                                            `${langCode}`,
                                                    })}
                                                    key={langCode}
                                                >
                                                    <Link
                                                        to={
                                                            isHome
                                                                ? `/${langCode}`
                                                                : `/${langCode}/${location.pathname.substring(
                                                                      location.pathname.indexOf(
                                                                          "/",
                                                                          1,
                                                                      ) + 1,
                                                                  )}`
                                                        }
                                                    >
                                                        {
                                                            availableLanguages[
                                                                langCode
                                                            ]
                                                        }
                                                    </Link>
                                                    {key !==
                                                        Object.keys(
                                                            availableLanguages,
                                                        ).length -
                                                            1 && (
                                                        <span
                                                            className={
                                                                style.langSeparator
                                                            }
                                                        >
                                                            /
                                                        </span>
                                                    )}
                                                </li>
                                            );
                                        },
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </header>
            {children}
        </LocaleProvider>
    );
};

const setCssVariables = theme => {
    document.documentElement.style.setProperty("--body--font", theme.textFont);
    document.documentElement.style.setProperty(
        "--body--color",
        theme.textColor,
    );
    document.documentElement.style.setProperty(
        "--heading--font",
        theme.titleFont,
    );
    document.documentElement.style.setProperty(
        "--primary-color",
        theme.primaryColor,
    );
    document.documentElement.style.setProperty(
        "--secondary-color",
        theme.secondaryColor,
    );

    document.documentElement.style.setProperty(
        "--scrollbar--width",
        `${window.innerWidth - document.body.offsetWidth}`,
    );
};

export default Layout;
