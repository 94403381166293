import { Location } from "@interfaces";
import classNames from "classnames";
import React from "react";

import * as style from "./style.module.scss";

interface Props {
    location: Location;
}
const Card = ({
    location: {
        name,
        addressLine1,
        addressLine2,
        city,
        postalCode,
        country,
        directionsUrl,
    },
}: Props): JSX.Element => {
    return (
        <div className="col-sm-6 col-md-12 col-xl-6">
            <div className={classNames(style.element)}>
                <h3>
                    {directionsUrl ? (
                        <a
                            href={directionsUrl}
                            target="_blank"
                            rel="noreferrer"
                            className={style.cardLink}
                        >
                            {name}
                        </a>
                    ) : (
                        name
                    )}
                </h3>
                <div>{addressLine1}</div>
                {addressLine2 && <div>{addressLine2}</div>}

                <div>{`${postalCode} ${city}`}</div>
                <div>{country}</div>
            </div>
        </div>
    );
};

export default Card;
