import { LocaleContext } from "@context";
import { t } from "@utils/Translations";
import React, { useContext } from "react";

import * as style from "./style.module.scss";

interface Props {
    title: string;
    children: string;
}

const List = ({ title, children }: Props): JSX.Element => {
    const skillTitle = title != "null" ? title : "Other";
    const locale = useContext(LocaleContext);

    return (
        <div className={style.element}>
            <h3>{t(skillTitle, locale)}</h3>
            {children}
        </div>
    );
};

export default List;
