import { TeamProfilesList as _TeamProfilesList } from "@components";
import { Section } from "@containers";
import React from "react";

interface Props {
    teamSlug: string;
    teamProfiles: string[];
    fill: string;
}

const TeamProfilesList = ({
    teamProfiles,
    teamSlug,
    fill,
}: Props): JSX.Element => (
    <Section>
        <_TeamProfilesList
            teamProfiles={teamProfiles}
            teamSlug={teamSlug}
            fill={fill}
        />
    </Section>
);

export default TeamProfilesList;
