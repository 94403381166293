import { Title } from "@components";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import { Accordion, Button } from "react-bootstrap";
import uuid from "react-uuid";

import * as style from "./style.module.scss";

interface Props {
    children: React.ReactNode;
    title?: string;
    subTitle?: string;
    align?: string;
    collapsable: boolean;
}

const Section = ({
    children,
    title,
    subTitle,
    align = "left",
    collapsable,
}: Props): JSX.Element => {
    const accordionId = uuid();
    return (
        <section className="mb-4">
            {collapsable ? (
                <Accordion defaultActiveKey={accordionId}>
                    <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey={accordionId}
                        className={style.accordionToggle}
                    >
                        <div className={`text-${align}`}>
                            {title && (
                                <Title tag="h2" className={style.title}>
                                    {title}
                                </Title>
                            )}

                            {subTitle && (
                                <Title
                                    tag="h3"
                                    className={classNames(style.subTitle, "h4")}
                                >
                                    {subTitle}
                                </Title>
                            )}
                        </div>
                        <FontAwesomeIcon
                            icon={faAngleDown}
                            aria-hidden="true"
                        />
                    </Accordion.Toggle>
                    <Accordion.Collapse
                        eventKey={accordionId}
                        className={style.accordionCollapse}
                    >
                        <div>{children}</div>
                    </Accordion.Collapse>
                </Accordion>
            ) : (
                <>
                    <div className={`text-${align}`}>
                        {title && (
                            <Title tag="h2" className={style.title}>
                                {title}
                            </Title>
                        )}

                        {subTitle && (
                            <Title
                                tag="h3"
                                className={classNames(style.subTitle, "h4")}
                            >
                                {subTitle}
                            </Title>
                        )}
                    </div>
                    {children}
                </>
            )}
        </section>
    );
};

export default Section;
