import { Button, ProfileImage } from "@components";
import { LocaleContext } from "@context";
import { t } from "@utils/Translations";
import React, { useContext } from "react";

import * as style from "./style.module.scss";

interface Props {
    profileImg: string;
    fullName: string;
    jobTitle: string;
    region: string;
    cvLink: string;
    fill: string;
}

const ProfileListItem = ({
    profileImg,
    fullName,
    jobTitle,
    region,
    cvLink,
}: Props): JSX.Element => {
    const locale = useContext(LocaleContext);
    return (
        <tr className={style.element}>
            <td className={style.imageCol}>
                <ProfileImage image={profileImg} />
            </td>

            <td className={style.nameCol}>
                <strong>{fullName}</strong>
            </td>
            <td className={style.jobCol}>
                <span className={style.jobtitle}>{jobTitle}</span>
            </td>

            <td className={style.regionCol}>
                {region && <strong>{region}</strong>}
            </td>
            <td className={style.btnCol}>
                <Button
                    label={t("view cv", locale)}
                    link={cvLink}
                    anchor={false}
                />
            </td>
        </tr>
    );
};
export default ProfileListItem;
