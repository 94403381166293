import { LocaleContext } from "@context";
import classNames from "classnames";
import moment from "moment/min/moment-with-locales";
import React, { useContext } from "react";

import RichText from "../../RichText";
import * as style from "./style.module.scss";

interface Experience {
    startMonth: string;
    startYear: string;
    endMonth: string;
    endYear: string;
    client: {
        company: string;
        cvSummary: string;
        projectName: string;
    };
    company: string;
    yourRole: string;
    summary: string;
    inProgress: boolean;
}

interface Props {
    experience: Experience;
    children: JSX.Element;
}

const Item = ({ experience, children }: Props): JSX.Element => {
    const locale = useContext(LocaleContext);

    moment.locale(locale.split("-")[0]);
    const startDate = moment(
        `${experience.startMonth} ${experience.startYear}`,
    );

    const startDateFormat = experience.startMonth ? "MMMM YYYY" : "YYYY";

    let endDate = null;

    if (experience.endMonth && experience.endYear) {
        endDate = moment(`${experience.endMonth} ${experience.endYear}`);
    }

    const endDateFormat = experience.endMonth ? "MMMM YYYY" : "YYYY";

    let dateString = startDate.format(startDateFormat);

    if (experience.inProgress) {
        dateString += ` - present`;
    } else {
        if (
            endDate &&
            startDate.format(startDateFormat) !== endDate.format(endDateFormat)
        ) {
            dateString += ` - ${endDate.format(endDateFormat)}`;
        }
    }
    return (
        <div className={style.element}>
            <div className={classNames(style.timeLine)}>
                <div className={style.period}>{dateString}</div>
                <div className={style.dot}></div>
            </div>
            <div className={style.content}>
                <h3>
                    {experience.client
                        ? experience.client.company +
                          (experience.client.projectName
                              ? " | " + experience.client.projectName
                              : "")
                        : ""}
                </h3>
                <h4 className="mb-2">{experience.yourRole}</h4>
                <RichText
                    rawText={
                        experience.client ? experience.client.cvSummary : ""
                    }
                />
                <RichText rawText={experience.summary} />
                <div className={style.skillsWrap}>{children}</div>
            </div>
        </div>
    );
};

export default Item;
