import { AdditionalSectionList as _AdditionalSectionList } from "@components";
import React from "react";

interface AdditionalSection {
    id: string;
    title: string;
    textBlock?: {
        raw: string;
    };
}
interface Props {
    additionalSections: AdditionalSection[];
}

const AdditionalSectionList = ({ additionalSections }: Props): JSX.Element => (
    <_AdditionalSectionList additionalSections={additionalSections} />
);

export default AdditionalSectionList;
