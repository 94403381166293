import { ProfileListItem } from "@components";
import { LocaleContext } from "@context";
import { Person } from "@interfaces";
import { t } from "@utils/Translations";
import React, { useContext } from "react";

import * as style from "./style.module.scss";

interface TeamProfile {
    person: Person;
}
interface Props {
    teamProfiles: TeamProfile[];
}

const TeamProfilesList = ({ teamProfiles }: Props): JSX.Element => {
    const locale = useContext(LocaleContext);
    return (
        <div className={style.element}>
            <h2>{t("our team", locale)}</h2>
            <hr />
            {teamProfiles && (
                <table className={style.teamProfileTable}>
                    <tbody>
                        {teamProfiles.map(teamProfile => (
                            <ProfileListItem
                                key={teamProfile.person.id}
                                profileImg={teamProfile.person.portrait}
                                fullName={`${teamProfile.person.firstName} ${teamProfile.person.name}`}
                                jobTitle={teamProfile.person.jobTitle}
                                region={teamProfile.person.region}
                                cvLink={teamProfile.person.slug}
                            />
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default TeamProfilesList;
