import { Experience as _Experience, Tag } from "@components";
import { LocaleContext } from "@context";
import { t } from "@utils/Translations";
import React, { useContext } from "react";

interface Experience {
    id: string;
    startMonth: string;
    startYear: string;
    endMonth: string;
    endYear: string;
    company: string;
    yourRole: string;
    summary: string;
    usedSkills: { id: string; title: string }[];
    inProgress: boolean;
}
interface Props {
    experiences: Experience[];
}

const Experience = ({ experiences }: Props): JSX.Element => {
    const locale = useContext(LocaleContext);
    const sortedExperiences = experiences
        .sort((a, b) => {
            return Number(b.inProgress) - Number(a.inProgress);
        })
        .sort((a, b) => {
            if (a.inProgress === b.inProgress) {
                return (
                    new Date(`${b.startMonth} ${b.startYear}`) -
                    new Date(`${a.startMonth} ${a.startYear}`)
                );
            }
        });

    return (
        <_Experience title={t("experience title", locale)}>
            {sortedExperiences &&
                sortedExperiences.map(experience => (
                    <_Experience.Item
                        key={experience.id}
                        experience={experience}
                    >
                        {experience.usedSkills && (
                            <ul className="list-unstyled mb-0 d-flex flex-wrap">
                                {experience.usedSkills.map(tag => (
                                    <Tag key={tag.id} label={tag.title} />
                                ))}
                            </ul>
                        )}
                    </_Experience.Item>
                ))}
        </_Experience>
    );
};
export default Experience;
