import { DocumentList as _DocumentList } from "@components";
import { Section } from "@containers";
import { LocaleContext } from "@context";
import { t } from "@utils/Translations";
import React, { useContext } from "react";

interface Props {
    documents: unknown;
}

const DocumentList = ({ documents }: Props): JSX.Element => {
    const locale = useContext(LocaleContext);
    return (
        <Section title={t("documents title", locale)}>
            <_DocumentList documents={documents} />
        </Section>
    );
};

export default DocumentList;
